<template>
  <div>
    <AppLoadingSpinner v-model="isOverlayLoading" />
    <v-card flat class="mx-4 my-4">
      <v-card-title>基本信息</v-card-title>
      <v-alert
        type="error"
        dense
        transition="slide-y-transition"
        :value="isTestPwdDuplicated"
      >
        更新失败，该测量码已被占用
      </v-alert>
      <v-alert
        type="success"
        dense
        transition="slide-y-transition"
        :value="isUpdateSuccess"
      >
        更新成功！
      </v-alert>
      <div class="px-6">
        <v-text-field
          filled
          dense
          label="分组名称"
          hint="测量组的显示名称"
          v-model="groupName"
          :rules="fieldRules.groupName"
        ></v-text-field>
        <v-text-field
          filled
          dense
          label="测量码"
          hint="唯一的测量码，用于进行连续测试。可点击右侧按钮自动生成测量码。"
          v-model="groupTestPwd"
          :rules="fieldRules.groupTestPwd"
          append-icon="mdi-refresh"
          @click:append="refreshGroupTestPwd"
        ></v-text-field>
      </div>
      <v-card-title>辅助功能</v-card-title>
      <div class="px-6">
        <v-switch
          inset
          :ripple="false"
          label="被试者测试完成后，是否能自行查看报告"
          v-model="isShowScaReport"
        ></v-switch>
        <v-switch
          inset
          :ripple="false"
          label="连续答题模式（开启：被试者必须按序做完全部关联的量表。关闭：被试者可自行选择一个量表进行测量。）"
          v-model="isContinuousTest"
        ></v-switch>
      </div>
      <v-card-title>二维码过期时间（0 表示无限制）</v-card-title>
      <div class="px-6 time-picker">
        <v-text-field
          class="mx-4 time-picker-input"
          outlined
          dense
          label="分钟"
          v-model="qrExpireTime.minutes"
          :rules="fieldRules.timeMinutes"
        >
          <template v-slot:prepend-inner>
            <v-btn icon color="green" small @click="qrExpireTime.minutes--">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </template>
          <template v-slot:append>
            <v-btn icon color="red" small @click="qrExpireTime.minutes++">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </div>
      <v-card-actions class="px-6 mb-6">
        <v-btn
          color="primary"
          depressed
          :disabled="isBtnLoading"
          :loading="isBtnLoading"
          @click="saveChanges"
        >
          确认修改
        </v-btn>
        <v-btn color="primary" depressed @click="showGroupQrCode">
          <v-icon dense class="mr-2">mdi-qrcode</v-icon>
          生成二维码
        </v-btn>
      </v-card-actions>
    </v-card>
    <AppDialog
      v-model="isShowQrCodeDialog"
      persistent
      color="primary"
      title="测量二维码"
      action-text="下载"
      action-icon="mdi-download"
      @confirm="downloadQrImg"
    >
      <a :href="qrCodeUrl" target="blank">
        <img width="100%" :src="qrCodeSourceUrl" />
      </a>
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import AppMessageBox from "@/components/AppMessageBox";
import { mapGetters, mapActions } from "vuex";
import {
  fetchGroupDetails,
  autoGenerateGroupTestPwd,
  checkGroupTestPwdDuplicated,
  saveInfoChanges,
  fetchGroupQrCode
} from "@/api/group";
import { downloadFileUrl } from "@/utils/download";
import { splitSecondToTime, mergeTimeToSecond } from "@/utils/dateTime";

export default {
  components: {
    AppLoadingSpinner,
    AppDialog,
    AppMessageBox
  },

  props: {
    guid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      originalGroupName: "",
      originalGroupTestPwd: "",
      originalIsShowScaReport: false,
      originalIsContinuousTest: false,
      groupName: "",
      groupTestPwd: "",
      isShowScaReport: false,
      isContinuousTest: false,
      originalQrExpireMinutes: 0,
      qrExpireTime: {
        minutes: 0,
        seconds: 0,
        isNegative: false
      },
      fieldRules: {
        groupName: [val => (val || "").length > 0 || "分组名称不能为空"],
        groupTestPwd: [val => (val || "").length > 0 || "测量码不能为空"],
        timeMinutes: [
          val =>
            (!isNaN(Number(val)) && Number(val) >= 0) || "必须输入大于零的数字",
          val => Number(val) < 9999 || "答题时间不能大于9999分钟"
        ]
      },
      // loading
      isBtnLoading: false,
      isOverlayLoading: false,
      // alerts
      isTestPwdDuplicated: false,
      isUpdateSuccess: false,
      // dialog
      isShowQrCodeDialog: false,
      errorMsg: "",
      // QrCode
      qrCodeSourceUrl: "",
      qrCodeUrl: ""
    };
  },

  watch: {
    async guid(newGuid) {
      // created 时不触发此方法
      await this.refreshGroupDetails(newGuid);
      // 要清空二维码信息
      this.qrCodeSourceUrl = "";
      this.qrCodeUrl = "";
    }
  },

  computed: {
    ...mapGetters({
      userGuid: "user/userGuid"
    }),
    isFieldChanged() {
      return (
        this.originalGroupName !== this.groupName ||
        this.originalGroupTestPwd !== this.groupTestPwd ||
        this.originalIsShowScaReport !== this.isShowScaReport ||
        this.originalIsContinuousTest !== this.isContinuousTest ||
        this.originalQrExpireMinutes !== this.qrExpireTime.minutes
      );
    }
  },

  methods: {
    ...mapActions({
      refreshGroupList: "group/refreshGroupList"
    }),
    async refreshGroupDetails(guid) {
      try {
        this.isOverlayLoading = true;
        let groupDetails = (await fetchGroupDetails(guid)) || {};
        this.originalGroupName = this.groupName = groupDetails.groupName;
        this.originalGroupTestPwd = this.groupTestPwd =
          groupDetails.groupTestPwd;
        this.originalIsShowScaReport = this.isShowScaReport =
          groupDetails.showScaReport;
        this.originalIsContinuousTest = this.isContinuousTest =
          groupDetails.isContinuousTest;
        this.qrExpireTime = splitSecondToTime(groupDetails.qrExpireSeconds);
        this.originalQrExpireMinutes = this.qrExpireTime.minutes;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isOverlayLoading = false;
    },
    async refreshGroupTestPwd() {
      try {
        this.isOverlayLoading = true;
        let generated = await autoGenerateGroupTestPwd(this.userGuid);
        if (generated) {
          this.groupTestPwd = generated.groupTestPwd;
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isOverlayLoading = false;
    },
    async showGroupQrCode() {
      this.isOverlayLoading = true;
      try {
        await this.saveChanges();
        let response = await fetchGroupQrCode({
          groupGuid: this.guid,
          groupName: this.groupName,
          groupTestPwd: this.groupTestPwd
        });
        this.qrCodeSourceUrl = response.source;
        this.qrCodeUrl = response.url;
        this.isShowQrCodeDialog = true;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isOverlayLoading = false;
    },
    async saveChanges() {
      // 未做任何更新，则调用api
      if (!this.isFieldChanged) {
        return;
      }
      try {
        this.isBtnLoading = true;
        // 只有更新测量码时，才需要重复性校验
        if (this.originalGroupTestPwd !== this.groupTestPwd) {
          let checkResults = await checkGroupTestPwdDuplicated(
            this.groupTestPwd
          );
          if (checkResults && checkResults.isDuplicated) {
            this.isTestPwdDuplicated = true;
            setTimeout(() => (this.isTestPwdDuplicated = false), 3000);
            this.isBtnLoading = false;
            return;
          }
        }
        // 保存修改
        await saveInfoChanges(this.guid, {
          groupName: this.groupName,
          groupTestPwd: this.groupTestPwd,
          showScaReport: this.isShowScaReport,
          isContinuousTest: this.isContinuousTest,
          qrExpireSeconds: mergeTimeToSecond(this.qrExpireTime)
        });
        // 当名字修改之后，需要更新group列表
        if (this.originalGroupName !== this.groupName) {
          await this.refreshGroupList();
        }
        // 重新获取group details
        await this.refreshGroupDetails(this.guid);
        this.isUpdateSuccess = true;
        setTimeout(() => (this.isUpdateSuccess = false), 2000);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    },
    // 下载
    downloadQrImg() {
      downloadFileUrl(this.qrCodeSourceUrl, `测量二维码_${this.groupName}.jpg`);
    }
  },

  async created() {
    await this.refreshGroupDetails(this.guid);
  }
};
</script>

<style lang="scss">
.time-picker {
  max-width: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  .time-picker-input {
    max-width: 180px;
    min-width: 150px;
    input {
      text-align: center;
    }
  }
}
</style>

<template>
  <v-container>
    <AppLoadingSpinner v-model="isLoading" />
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title>被试者属性名称</v-card-title>
          <v-card-text>点击“显示名称”和“排序”下方的内容即可修改</v-card-text>
          <v-data-table
            class="mx-8"
            :headers="fieldConfigHeaders"
            :items="fieldConfigList"
            item-key="guid"
            :sort-by="['order']"
            :sort-desc="[false]"
            disable-pagination
            hide-default-footer
            :loading="isConfigTableLoading"
            loading-text="正在读取属性名称，请稍候..."
            no-data-text="未找到任何属性"
            no-results-text="未找到任何匹配的属性"
          >
            <template v-slot:[`item.fieldAlias`]="{ item }">
              <v-edit-dialog
                :return-value.sync="item.fieldAlias"
                large
                save-text="保存"
                cancel-text="取消"
                @save="
                  fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'fieldAlias',
                    item.fieldAlias
                  )
                "
              >
                <div>{{ item.fieldAlias }}</div>
                <template v-slot:input>
                  <div class="mt-4 text-h6">更新显示名称</div>
                  <v-text-field
                    single-line
                    dense
                    autofocus
                    label="显示名称"
                    v-model="item.fieldAlias"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.valueRange`]="{ item }" ]>
              <v-edit-dialog
                v-if="item.isAllowValueRange"
                large
                persistent
                save-text="保存"
                cancel-text="取消"
                @open="originalValueRange = [...item.valueRange]"
                @cancel="item.valueRange = originalValueRange"
                @close="newValueRangeItem = ''"
                @save="
                  fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'valueRange',
                    JSON.stringify(item.valueRange)
                  )
                "
              >
                <div>
                  <v-chip
                    v-for="(val, idx) in item.valueRange"
                    :key="idx"
                    class="mr-1 my-1"
                    small
                  >
                    {{ val }}
                  </v-chip>
                  <span
                    v-if="!item.valueRange || !item.valueRange.length"
                    class="text-caption"
                  >
                    点击添加取值范围
                  </span>
                </div>
                <template v-slot:input>
                  <div class="mt-4 text-h6">更新取值范围</div>
                  <div>
                    <v-chip
                      v-for="(val, idx) in item.valueRange"
                      :key="idx"
                      class="mr-1 my-1"
                      small
                      close
                      @click:close="item.valueRange.splice(idx, 1)"
                    >
                      {{ val }}
                    </v-chip>
                  </div>
                  <v-text-field
                    single-line
                    autofocus
                    label="输入要添加的值"
                    v-model="newValueRangeItem"
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        color="primary"
                        small
                        @click="item.valueRange.push(newValueRangeItem)"
                      >
                        <v-icon>mdi-plus</v-icon>
                        添加
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.rulesGuid`]="{ item }" ]>
              <v-edit-dialog
                v-if="!item.valueRange || !item.valueRange.length"
                large
                persistent
                save-text="保存"
                cancel-text="取消"
                @save="
                  fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'rulesGuid',
                    item.rulesGuid
                  )
                "
              >
                <div>
                  <v-chip v-if="item.rulesGuid" class="mr-1 my-1" small>
                    {{ getConfigRuleNameFromGuid(item.rulesGuid) }}
                  </v-chip>
                  <span v-else class="text-caption">
                    点击添加属性类型
                  </span>
                </div>
                <template v-slot:input>
                  <div class="mt-4 mr-6 text-h6">更新属性的类型</div>
                  <v-select
                    single-line
                    label="选择属性的类型"
                    :items="fieldConfigRulesList"
                    item-text="name"
                    item-value="guid"
                    v-model="item.rulesGuid"
                  ></v-select>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.isVisible`]="{ item }">
              <v-switch
                inset
                v-model="item.isVisible"
                @change="
                  fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'isVisible',
                    item.isVisible
                  )
                "
                :disabled="
                  item.fieldName === 'gender' || item.fieldName === 'age'
                "
              ></v-switch>
            </template>
            <template v-slot:[`item.isRequired`]="{ item }">
              <v-switch
                inset
                v-model="item.isRequired"
                @change="
                  fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'isRequired',
                    item.isRequired
                  )
                "
                :disabled="
                  item.fieldName === 'gender' || item.fieldName === 'age'
                "
              ></v-switch>
            </template>
            <template v-slot:[`item.isVisibleInTable`]="{ item }">
              <v-switch
                inset
                v-model="item.isVisibleInTable"
                @change="
                  fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'isVisibleInTable',
                    item.isVisibleInTable
                  )
                "
              ></v-switch>
            </template>
            <template v-slot:[`item.isVisibleInReport`]="{ item }">
              <v-switch
                inset
                v-model="item.isVisibleInReport"
                @change="
                  fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'isVisibleInReport',
                    item.isVisibleInReport
                  )
                "
              ></v-switch>
            </template>
            <template v-slot:[`item.order`]="{ item }">
              <v-edit-dialog
                :return-value.sync="item.order"
                large
                save-text="保存"
                cancel-text="取消"
                @save="
                  fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'order',
                    Number(item.order)
                  )
                "
              >
                <div>{{ item.order }}</div>
                <template v-slot:input>
                  <div class="mt-4 text-h6">更新字段排序</div>
                  <v-text-field
                    single-line
                    dense
                    autofocus
                    label="字段排序"
                    v-model="item.order"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-container>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import {
  getUserFieldConfig,
  getTestGroupFieldConfig,
  getAllFieldConfigRules,
  getAllGroupFieldConfigRules,
  updateUserFieldConfig,
  updateTestGroupFieldConfig
} from "@/api/fieldConfig";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox
  },

  props: {
    userEntity: {
      type: String
    },
    userGuid: {
      type: String
    },
    groupGuid: {
      type: String
    }
  },

  data() {
    return {
      isLoading: false,
      isConfigTableLoading: false,
      fieldConfigRulesList: [],
      fieldConfigList: [],
      fieldConfigHeaders: [],
      // value range editing
      originalValueRange: [],
      newValueRangeItem: "",
      // rule message editing
      originalRegexRule: {},
      newRegexRule: {},
      fieldRules: {
        fieldMaxLength: [
          val => (val || "").length < 10 || "自定义标题不能超过10个字符"
        ]
      },
      errorMsg: ""
    };
  },

  watch: {
    async groupGuid(newGuid) {
      this.fieldConfigList = await getTestGroupFieldConfig(newGuid);
    }
  },

  methods: {
    initFieldConfigHeaders() {
      this.fieldConfigHeaders = [
        {
          text: "属性说明",
          value: "fieldDesc"
        },
        {
          text: this.userGuid ? "显示名称" : "显示名称（答题端）",
          value: "fieldAlias"
        },
        {
          text: this.userGuid ? "下拉取值范围" : "下拉取值范围（答题端）",
          value: "valueRange"
        },
        {
          text: this.userGuid ? "属性类型" : "属性类型（答题端）",
          value: "rulesGuid"
        },
        {
          text: this.userGuid ? "是否显示" : "是否显示（答题端）",
          value: "isVisible"
        },
        {
          text: this.userGuid ? "是否必填" : "是否必填（答题端）",
          value: "isRequired"
        }
      ];
      if (this.userGuid) {
        this.fieldConfigHeaders.push({
          text: "是否在案例列表中显示",
          value: "isVisibleInTable"
        });
        this.fieldConfigHeaders.push({
          text: "是否在报告中显示",
          value: "isVisibleInReport"
        });
      }
      this.fieldConfigHeaders.push({
        text: this.userGuid ? "排序" : "排序（答题端）",
        value: "order"
      });
    },
    getConfigRuleNameFromGuid(rulesGuid) {
      let configRules = this.fieldConfigRulesList.find(
        fcr => fcr.guid === rulesGuid
      );
      return configRules ? configRules.name : "";
    },
    async fetchFieldConfigList() {
      try {
        this.isConfigTableLoading = true;
        if (this.userGuid) {
          this.fieldConfigList = await getUserFieldConfig(
            this.userEntity,
            this.userGuid
          );
        } else if (this.groupGuid) {
          this.fieldConfigList = await getTestGroupFieldConfig(this.groupGuid);
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isConfigTableLoading = false;
    },
    async fetchFieldConfigRulesList() {
      try {
        this.isConfigTableLoading = true;
        if (this.userGuid) {
          this.fieldConfigRulesList = await getAllFieldConfigRules(
            this.userEntity,
            this.userGuid
          );
        } else if (this.groupGuid) {
          this.fieldConfigRulesList = await getAllGroupFieldConfigRules(
            this.groupGuid
          );
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isConfigTableLoading = false;
    },
    async fieldValueChanged(fieldGuid, fieldName, newValName, newVal) {
      try {
        this.isLoading = true;
        let configToUpdate = { guid: fieldGuid, fieldName };
        configToUpdate[newValName] = newVal;
        if (this.userGuid) {
          await updateUserFieldConfig([newValName], configToUpdate);
        } else if (this.groupGuid) {
          await updateTestGroupFieldConfig(
            this.groupGuid,
            [newValName],
            configToUpdate
          );
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    }
  },

  created() {
    this.initFieldConfigHeaders();
    this.fetchFieldConfigList();
    this.fetchFieldConfigRulesList();
  }
};
</script>

<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="mx-4 my-4">
      <v-card-title>测量统计信息</v-card-title>
      <div class="px-6 d-flex flex-wrap align-center">
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="350px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mr-4 filter-item"
              v-model="filterDateRangeText"
              prepend-inner-icon="mdi-calendar-text"
              label="筛选日期"
              filled
              dense
              hide-details="auto"
              clearable
              v-on="on"
              v-bind="attrs"
              @click:clear="filterDateRange = []"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterDateRange"
            full-width
            no-title
            range
            show-adjacent-months
            locale="zh-cn"
            :day-format="d => d.split('-')[2]"
          ></v-date-picker>
        </v-menu>
        <AppTooltipBtn
          class="ml-6 my-4"
          color="primary"
          label="筛选"
          icon="mdi-filter"
          @click="reloadGroupTestData(guid)"
        />
      </div>
      <div
        v-if="!!groupTestCaseStatList.length"
        class="px-6 mt-10 stat-chart-div"
      >
        <div>
          <v-btn-toggle
            v-model="currentChartMode"
            mandatory
            dense
            color="primary"
          >
            <v-btn>测量日期</v-btn>
            <v-btn>量表</v-btn>
          </v-btn-toggle>
          <AppTooltipBtn
            class="ml-6 my-4"
            color="primary"
            icon="mdi-refresh"
            tooltip="刷新图表数据"
            @click="reloadGroupTestData(guid)"
          />
        </div>
        <div id="echartsgroupstat" class="mt-6"></div>
      </div>
      <div v-else class="ml-6 my-6 text-body-1">
        <span>未找到任何案例</span>
      </div>
    </v-card>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import _ from "lodash";
import { groupTestCases } from "@/api/statistic";
import { createdDateGroupByFunc } from "@/utils/dateTime";
import {
  drawTestCaseStatChart,
  disposeChart
} from "@/utils/charts/chartsTestCaseStat";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppTooltipBtn
  },

  props: {
    guid: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      groupTestCaseStatList: [],
      filterDateRange: [],
      currentChartMode: 0,
      errorMsg: ""
    };
  },

  watch: {
    async guid(newGuid) {
      this.filterDateRange = [];
      await this.reloadGroupTestData(newGuid);
    },
    currentChartMode() {
      this.drawGroupTestStatData();
    }
  },

  computed: {
    filterDateRangeStart() {
      let dRange = this.filterDateRange;
      if (dRange.length === 1) {
        return dRange[0];
      } else if (dRange.length === 2) {
        return _.min(dRange);
      }
      return null;
    },
    filterDateRangeEnd() {
      let dRange = this.filterDateRange;
      if (dRange.length === 1) {
        return null;
      } else if (dRange.length === 2) {
        return _.max(dRange);
      }
      return null;
    },
    filterDateRangeText() {
      let dText = "";
      if (this.filterDateRangeStart) {
        dText += this.filterDateRangeStart;
      }
      if (this.filterDateRangeEnd) {
        dText += ` ~ ${this.filterDateRangeEnd}`;
      }
      return dText;
    },
    caseGroupedKeys() {
      if (this.groupTestCaseStatList.length) {
        let lodChain = _.chain(this.groupTestCaseStatList);
        if (this.currentChartMode === 0) {
          lodChain = lodChain.groupBy(createdDateGroupByFunc);
        } else if (this.currentChartMode === 1) {
          lodChain = lodChain.groupBy(ca => ca.lbDispName);
        }
        return lodChain.map((_, key) => key).value();
      }
      return [];
    }
  },

  methods: {
    async reloadGroupTestData(groupGuid) {
      try {
        this.isLoading = true;
        this.groupTestCaseStatList = await groupTestCases(
          groupGuid,
          this.filterDateRangeStart,
          this.filterDateRangeEnd
        );
        if (this.groupTestCaseStatList.length) {
          disposeChart();
          this.$nextTick(() => {
            this.drawGroupTestStatData();
          });
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    getCaseGroupedCount(statusName) {
      if (this.groupTestCaseStatList.length && this.caseGroupedKeys.length) {
        let lodChain = _.chain(this.groupTestCaseStatList).filter({
          status: statusName
        });
        return this.caseGroupedKeys.map(key => {
          let caseWithKey = null;
          if (this.currentChartMode === 0) {
            caseWithKey = lodChain
              .countBy(gtc => gtc.createdDate.includes(key))
              .value().true;
          } else if (this.currentChartMode === 1) {
            caseWithKey = lodChain
              .countBy(gtc => gtc.lbDispName === key)
              .value().true;
          }
          return caseWithKey || "";
        });
      }
      return [];
    },
    // echarts 图表
    drawGroupTestStatData() {
      try {
        drawTestCaseStatChart(document.getElementById("echartsgroupstat"), {
          chartTitle: `${this.name}`,
          chartSubtext: "测量人数统计",
          dateSeries: this.caseGroupedKeys,
          visualMarkData: {
            completed: Object.values(this.getCaseGroupedCount("completed")),
            waitToStart: Object.values(
              this.getCaseGroupedCount("wait_to_start")
            ),
            inProgress: Object.values(this.getCaseGroupedCount("in_progress")),
            saved: Object.values(this.getCaseGroupedCount("saved")),
            cancelled: Object.values(this.getCaseGroupedCount("cancelled"))
          }
        });
      } catch (err) {
        this.errorMsg = err.message;
      }
    }
  },

  async created() {
    await this.reloadGroupTestData(this.guid);
  }
};
</script>

<style lang="scss" scoped>
.stat-chart-div {
  overflow: auto;
}
#echartsgroupstat {
  width: 600px;
  height: 600px;
}
.filter-item {
  max-width: 400px;
  min-width: 200px;
}
</style>

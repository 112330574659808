<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card v-if="!isGroupDeleted">
      <v-tabs show-arrows>
        <v-tab>测量统计</v-tab>
        <v-tab>基本设置</v-tab>
        <v-tab>关联量表设置</v-tab>
        <v-tab>关联单位设置</v-tab>
        <v-tab>答题端显示设置</v-tab>
        <v-spacer></v-spacer>
        <v-btn
          class="mt-2 mr-4"
          text
          color="red"
          @click="isDeletingGroup = true"
        >
          删除分组
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-tab-item>
          <AdminConfigGroupStatistic :guid="guid" :name="groupName" />
        </v-tab-item>
        <v-tab-item>
          <AdminConfigGroupInfo :guid="guid" />
        </v-tab-item>
        <v-tab-item>
          <AdminConfigGroupLb :guid="guid" />
        </v-tab-item>
        <v-tab-item>
          <AdminConfigGroupDept :groupGuid="guid" />
        </v-tab-item>
        <v-tab-item>
          <FieldConfigEditor :group-guid="guid" />
        </v-tab-item>
      </v-tabs>
    </v-card>
    <AppDialog
      v-model="isDeletingGroup"
      size="small"
      :title="`确定要删除“${groupName}”吗？`"
      color="red"
      action-text="删除"
      :loading="isDialogLoading"
      @confirm="removeOneGroup"
    >
      删除后无法恢复
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AdminConfigGroupStatistic from "@/components/admin/AdminConfigGroupStatistic";
import AdminConfigGroupInfo from "@/components/admin/AdminConfigGroupInfo";
import AdminConfigGroupLb from "@/components/admin/AdminConfigGroupLb";
import AdminConfigGroupDept from "@/components/admin/AdminConfigGroupDept";
import FieldConfigEditor from "@/components/FieldConfigEditor";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import AppMessageBox from "@/components/AppMessageBox";
import { fetchGroupName, deleteOneGroup } from "@/api/group";
import { mapActions } from "vuex";

export default {
  components: {
    AdminConfigGroupStatistic,
    AdminConfigGroupInfo,
    AdminConfigGroupLb,
    AdminConfigGroupDept,
    FieldConfigEditor,
    AppLoadingSpinner,
    AppDialog,
    AppMessageBox
  },

  props: {
    guid: {
      type: String,
      required: true
    }
  },

  watch: {
    guid(newGuid) {
      this.isGroupDeleted = false;
      this.getGroupName(newGuid);
    }
  },

  data() {
    return {
      groupName: "",
      isDeletingGroup: false,
      isGroupDeleted: false,
      isLoading: false,
      isDialogLoading: false,
      errorMsg: ""
    };
  },

  methods: {
    ...mapActions({
      refreshGroupList: "group/refreshGroupList"
    }),
    async getGroupName(guid) {
      try {
        this.isLoading = true;
        this.groupName = await fetchGroupName(guid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async removeOneGroup() {
      try {
        this.isDialogLoading = true;
        await deleteOneGroup(this.guid);
        await this.refreshGroupList();
        this.isGroupDeleted = true;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDeletingGroup = false;
      this.isDialogLoading = false;
    }
  },

  created() {
    this.getGroupName(this.guid);
  }
};
</script>
